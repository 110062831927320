<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  import Logo from 'ui/assets/logo-full.svg';

  export let title: string = null;
  export let closeable = true;
  export let poveredBy = false;

  const dispatch = createEventDispatcher();
  let blackout: Element;
  let dialog: Element;

  //* Example usage */

  // const modal = mountModal(TestModal, props);
  // modal.$on('event', () => {});

  export function close() {
    if (!closeable) {
      return;
    }

    dispatch('close');

    blackout.classList.add('animate__fadeOut');
    dialog.classList.add('animate__fadeOutDown');
  }
</script>

<div class="modal" {...$$restProps}>
  <div class="modal-blackout animate__animated animate__fadeIn" bind:this={blackout} on:click={close} />

  <div class="flex min-h-full items-end sm:items-center justify-center py-3">
    <div class="modal-dialog animate__animated animate__fadeInUp" class:closeable bind:this={dialog}>
      <div class="modal-content">
        {#if closeable}
          <button type="button" aria-hidden="true" class="close" on:click={close} />
        {/if}

        {#if poveredBy}
          <div class="logo my-2">
            Powered by
            <Logo class="-mt-1" />
          </div>
        {/if}

        {#if title}
          <div class="modal-header">
            <h5 class="modal-title">{title}</h5>
          </div>
        {/if}

        <div class="modal-body">
          <slot />
        </div>

        <div class="modal-footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</div>

<style scoped>
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--z-modal);
    padding: 0 20px;
    overflow-y: auto;
  }

  .modal-blackout {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(47, 47, 47, 0.7);
    z-index: 1;
    animation-duration: 0.5s;
  }

  .modal-dialog {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--base-bg);
    color: var(--base-color);
    animation-duration: 0.4s;
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: var(--base-bg);
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
  }

  .modal-body {
    position: relative;
    flex: 1 1 auto;
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .closeable {
    margin-top: 22px;
  }

  .close {
    background-color: var(--base-bg);
    position: absolute;
    top: -44px;
    width: 44px;
    height: 44px;
    left: calc(50% - 22px);
    border-radius: 50%;
    border: 0;
  }

  .close::before,
  .close::after {
    position: absolute;
    left: 21px;
    top: 13px;
    content: ' ';
    height: 18px;
    width: 2px;
    background-color: var(--base-color);
  }
  .close::before {
    transform: rotate(45deg);
  }
  .close::after {
    transform: rotate(-45deg);
  }

  .logo {
    font-size: 11px;
    text-align: center;
  }

  .logo :global(.u-icon) {
    width: auto;
    height: 15px;
    margin-left: 0.5em;
  }
</style>
